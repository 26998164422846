<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="booking">
      <v-flex xs12 pt-4 v-if="booking.length>0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sl. No</th>
                <th class="text-left">Ticket No</th>
                <th class="text-left">Accomodation_Name</th>
                <th class="text-left">Name_of_persons</th>
                <th class="text-left">Booking_Received_Date</th>
                <th class="text-left">
                  Reservation_Date
                  <tr>
                    <th width="100%" class="text-left">From<span style="color:white">Reservation_Date</span></th>
                    <th class="text-right">To<span style="color:white">Reservation_Date</span> </th>
                  </tr>
                </th>
                <th class="text-left">Phone</th>
                <th class="text-left">Email</th>
                <th class="text-left">Food Preference</th>
                <th class="text-left">Date_of_Birth</th>
                <th class="text-left">Gender</th>
                <th class="text-left">Guest Type</th>
                <th class="text-left">Amount for Accomodation</th>
                <th class="text-left">Amount for Checkpost Entry</th>
                <th class="text-left">Amount for Vehicle</th>
                <th class="text-left">Amount settled to account(Total-PG)</th>
                <th class="text-left">Amount Paid(Total)</th>
                <th class="text-left">Refund Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 90px;" v-for="(item, i) in booking" :key="i">
                <td v-if="booking">
                  <span v-if="currentPage > 1">{{
                    (currentPage - 1) * count + i + 1
                  }}</span>
                  <span v-else>{{ i + 1 }}</span>
                </td>
                <td><b> #{{ item.payment.ticket.ticketNo }}</b>
                <br />
                  <span
                    v-if="item.payment.status == 'Cancelled'"
                    style="color: red"
                  >
                    <b> ({{ item.payment.status }})</b>
                  </span></td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      {{ sub.programme.progName }},
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span class="text-capitalize" :key="j"
                          >{{ step.name }},<br
                        /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>{{ item.payment.init_date.slice(0, 10) }}</td>
                <td>
                  <tbody>
                    <td class="text-left" width="66%">
                      <v-layout wrap>
                        <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                          {{ sub.bookingDate.slice(0, 10) }}
                        </v-flex>
                      </v-layout>
                    </td>
                    <td>
                      <v-layout wrap>
                        <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                          {{ sub.bookingDate.slice(0, 10) }}
                        </v-flex>
                      </v-layout>
                    </td>
                  </tbody>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span :key="j">{{ step.phoneno }},<br /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span :key="j">{{ step.email }},<br /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span :key="j" v-if="step.foodPreference">
                          <v-layout wrap v-if="step.foodPreference.length > 0">
                            <v-flex
                              xs12
                              align-self-center
                              pa-1
                              v-for="(food, f) in step.foodPreference"
                              :key="f"
                            >
                              <span> {{ food }}, <br> </span>
                            </v-flex>
                          </v-layout>
                        </span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span :key="j">{{ step.dob.slice(0, 10) }} ({{ step.age }}),<br /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span :key="j">{{ step.gender }},<br /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template v-for="(step, j) in sub.guest">
                        <span class="text-capitalize" :key="j"
                          >{{ step.guestType }},<br
                        /></span>
                      </template>
                    </v-flex>
                  </v-layout>
                </td>
                <td>
                  <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template>
                        <span class="text-capitalize"
                          >{{ sumCottage(item.booking) }} INR</span
                        >
                      </template>
                    </v-flex>
                  </v-layout>
                </td>

                <td>
                  <!-- {{ item.guestsEntranceTicketTotal }} INR -->
                  <span v-if="item.guestsEntranceTicketCharge">
                    {{ item.guestsEntranceTicketCharge }} INR</span
                  >
                  <span v-else> Nil </span>
                  </td>
                <td>
                  <!-- {{ item.vehicleTotal }} INR -->
                  <span v-if="item.vehicleTotal">
                    {{ item.vehicleTotal }} INR</span
                  >
                  <span v-else> Nil </span>
                  </td>
                <td>
                  {{ item.payment.total - sumGateway(item.booking) }} INR
                  <!-- <v-layout wrap>
                    <v-flex xs12 v-for="(sub, i) in item.booking" :key="i">
                      <template>
                        <span class="text-capitalize">{{ sumGateway(item.booking) }}</span>
                      </template>
                    </v-flex>
                  </v-layout>  -->
                </td>
                <td>{{ item.payment.total }} INR</td>
                <td>
                  <span v-if="item.payment.status == 'Cancelled'">
                    <v-layout wrap>
                      <v-flex xs12 v-for="(ref, f) in item.booking" :key="f">
                      <template>
                        <!-- <span class="text-capitalize"
                          >{{ ref.canceldata.refundAmount }} INR</span
                        > -->
                        <span class="text-capitalize"
                          >{{ sumRefund(item.booking) }} INR</span
                        >
                      </template>
                    </v-flex>
                    </v-layout>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 pt-4 v-else>
        <span style="font-family:poppinsregular" >No Bookings found!</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="booking.length>0">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
// import axios from "axios";
export default {
  props: ["booking", "pages", "count"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
      cottageTotal: 0,
      hj: "Booked for which Date and Timeslot",
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    currentPage() {
      this.winStepper();
    },
  },
  mounted() {
    // console.log("hk", this.booking);
  },
  methods: {
    sumRefund(item) {
      let sum = 0;
      let cottage = item.filter(function (val) {
        if (val.canceldata) {
          sum += val.canceldata.refundAmount;
        }
        return true;
      });
      item = cottage;
      // console.log(cottage)
      return sum;
    },
    sumCottage(item) {
      let sum = 0;
      let cottage = item.filter(function (val) {
        if (val.isCottage) {
          sum += val.programTotal;
        }
        return true;
      });
      item = cottage;
      // console.log(cottage)
      return sum;
    },
    sumGateway(item) {
      let sum = 0;
      let pay = item.filter(function (val) {
        sum += val.paymentgatewayCharge;
        return true;
      });
      item = pay;
      // console.log(cottage)
      return sum;
    },
    winStepper() {
      this.$emit("stepper", {
        getData: true,
        currentPage: this.currentPage,
        pages: this.pages,
      });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
/* td {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100% !important;
}
th {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100% !important;
} */

td {
  font-family: poppinsregular;
  text-align: left;
}
th {
  font-family: poppinssemibold;
}
</style>